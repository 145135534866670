@import './variables';

.react-datepicker-wrapper, 
.react-datepicker {
    font-family: AppFont, sans-serif !important;
    font-size: 1em !important;
}
.react-datepicker__navigation {
    top: 9px !important;
    outline: none;
}
.react-datepicker__year-wrapper {
    display: block !important;
    font-family: sans-serif !important;
}
.react-datepicker__close-icon::after {
    background: $white !important;
    color: var(--black-65) !important;
    font-size: 34px !important;
}
.react-datepicker__input-container {
    input {
        background: url(../assets/images/calendar.png) no-repeat 98% center;
    }
}
.react-datepicker__day-name, 
.react-datepicker__day, 
.react-datepicker__time-name {
    width: 2.4em !important;
    line-height: 2.2em !important;
    margin: 0 !important;
}

.react-datepicker__year .react-datepicker__year-text,
.react-datepicker__month .react-datepicker__month-text {
    width: 5rem !important;
    margin: 5px !important;
}

.react-datepicker__day--today, 
.react-datepicker__month-text--today, 
.react-datepicker__year-text--today {
    font-weight: normal!important;
}

.react-datepicker-popper[data-placement^=top] {
    padding-bottom: 0 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: var(--medium-blue-60) !important;
}
