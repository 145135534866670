.iframeContainer {
    width: 100%;
    height: 100vh;
}

iframe {
    width: 100%;
    height: 100vh;
    .error-container {
        display: none;
    }    

}

.error-page {
    .error-container {
        display: none;
    }
} 

// .error-container {
//     display: none;
// }
  