@import '../../styles/variables';

.loginPage {
  background-color: $primary-background;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  overflow-x: hidden;
  padding: 0 1rem 2.875rem;
  .loginFormContainer {
    max-width: 20rem;
    width: 100%;
    margin-top: 4rem;
    .brand {
      margin-bottom: 3.562rem;
      margin-left: 30px;
      margin-top: 1rem;
      display: flex;
      img {
        margin-right: 1em;
      }
    }
    .forgotPassword {
      cursor: pointer;
      font-size: 0.875rem;
    }
    .loginTitle {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 2rem;
      margin-bottom: 2rem;
    }
    .togglePassword {
      position: relative;
      .eyeIcon {
        cursor: pointer;
        position: absolute;
        left: 17.938rem;
        top: 2.5rem;
      }
    }
    .tickIcon {
      position: absolute;
      right: 2.7rem;
      top: 2.5rem;
    }
    .passwordBox {
      padding-right: 2.5rem;
    }
    .backToLoginFooter {
      bottom: 0;
      color: var(--primary-btn-background);
      cursor: pointer;
      margin-top: 20px;
      position: sticky;
      text-align: center;
      top: 100%;
      width: 100%;
    }
  }
}
