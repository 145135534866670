@import '../../styles/variables';

.layout {
  min-height: 100%;
  .contentCenter {
    max-width: $main-content-max-width;
    width: 100%;
  }
  .header {
    margin: 0 -2.25rem;
  }
  .body {
    display: flex;
    .sidemenu {
      width: 15rem;
    }
  }
}

.dashboardLayout {
  min-height: 100%;
  .contentCenter {
    width: 100%;
  }
  .header {
    margin: 0 -2.25rem;
  }
  .body {
    display: flex;
    .sidemenu {
      width: 15rem;
    }
  }
}
