@import '../../styles/variables';

.checkboxWrapper {
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  .checkbox {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    &:checked {
      &::after {
        background: var(--checkbox-selection-color);
        border: none;
      }
    }
    &:focus {
      &::after {
        border: 1px solid $field-focus-border-color !important;
      }
    }
    &::after {
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      border: 1px solid var(--secondary-btn-text-color);
      border-radius: 0.25rem;
      content: '\2714';
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background: $white;
      color: $white;
      overflow: hidden;
      padding-left: 1px; // to make the tick looks centered
    }
  }
}
.checkboxLabel {
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1rem;
  color: $field-label-color;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  &:not(:last-child) {
    margin-right: 1.5rem;
  }
  &.checkboxGroupLabel {
    height: 40px;
  }
}
.error {
  padding-left: 1px;
  padding-top: 1px;
  font-size: 0.85rem;
  min-height: 1.5rem;
  color: $field-error-color;
}
