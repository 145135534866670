@import '../../styles/variables';

.textInput {
  font-size: 0.875rem;
  label {
    margin-bottom: 0.5rem;
    color: $field-label-color;
    line-height: 1.25rem;
    padding-left: 1px;
  }
  .input {
    border: 0.5px solid $field-border-color;
    border-radius: 0.187rem;
    padding: 0.625rem 0.75rem 0.75rem;
    width: 100%;
    color: $field-text-color;
    &:hover {
      border-color: $field-focus-border-color;
    }
    &:focus {
      box-shadow: inset 0px 4px 8px $field-focus-box-shadow-color;
      border-color: $field-focus-border-color;
      outline: none;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $field-placeholder-color;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $field-placeholder-color;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $field-placeholder-color;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $field-placeholder-color;
    }
  }
  input {
    @extend .input;
    height: 2.5rem;
  }
  textarea {
    @extend .input;
    resize: none;
  }
  .error {
    padding-left: 1px;
    padding-top: 1px;
    font-size: 0.85rem;
    min-height: 1.5rem;
    color: $field-error-color;
  }
  &.danger {
    input {
      border-color: $field-error-color;
    }
  }
}
