
.detail {
  border-radius: 12px;
  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: .75rem 1.25rem;
    .headerLabel {
      color: var(--primary-title-text-color);
      font-size: 1.25rem;
      letter-spacing: -.02em;
      line-height: 2rem;
    }
  }
}