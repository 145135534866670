@import '../../styles/variables';

.checkboxWrapper {
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
  .checkbox {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    &:checked {
      &::after {
        background: var(--checkbox-selection-color);
        border: none;
      }
    }
    &:focus {
      &::after {
        border: 1px solid $field-focus-border-color !important;
      }
    }
    &::after {
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      border: 1px solid $field-border-color;
      border-radius: 0.25rem;
      content: '\2714';
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background: $white;
      color: $white;
      overflow: hidden;
      padding-left: 1px; // to make the tick looks centered
    }
  }
}
.checkboxLabel {
  cursor: pointer;
  font-size: 0.875rem;
  margin-left: 0.5rem;
  line-height: 1rem;
  color: $field-label-color;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
