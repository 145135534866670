@import '../../styles/variables';

.fieldSection {
  display: flex;
  width: 100% !important;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.4rem;
  padding: 0.5rem;
  .fieldLabel{
    display: block;
    width: 40%;
    font-weight: 400;
  }
  .fieldValue {
    display: block;
    width: 50%;
    padding: 0rem 1rem;
  }
}

.alertItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.alertModalIcon {
  max-height: 25vh;
  padding-bottom: 2rem;

}

.alertModalText {
  text-align: center;
  font-size: large;
  padding-bottom: 1rem;
}

.closeButton {
  min-width: 10rem;
  min-height: 2.5rem;
  border: 5px solid #2514BE;
  border-radius: 5px;
  color: #fff;
  align-self: center;
  background-color: #2514BE;
}
.info {
  margin-left: 1rem;
  margin-bottom: 0;
  margin-top: 1rem;
}