@import '../../styles/variables';

.loaderBackdrop {
  z-index: 1300;
  display: flex;
  align-items: center;
  &.fullScreen {
    background: rgba(var(--black-rgb), 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .loaderPosition {
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);
      .loader {
        border: 8px solid $white;
        border-radius: 50%;
        border-top: 8px solid $loader-color;
        width: 50px;
        height: 50px;
        -webkit-animation: spin 1.5s linear infinite;
        /* Safari */
        animation: spin 1.5s linear infinite;
      }
    }
  }
  background: transparent;
  .loaderPosition {
    position: relative;
    left: 50%;
    .loader {
      border: 6px solid $white;
      border-radius: 50%;
      border-top: 6px solid $loader-color;
      width: 30px;
      height: 30px;
      -webkit-animation: spin 1.5s linear infinite;
      /* Safari */
      animation: spin 1.5s linear infinite;
    }
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.patient-loader {
  z-index: 1300;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  background: $white;
  .loader-position {
    position: relative;
    left: 50%;
    .loader {
      border: 6px solid $white;
      border-radius: 50%;
      border-top: 6px solid $loader-color;
      width: 30px;
      height: 30px;
      -webkit-animation: spin 1.5s linear infinite;
      /* Safari */
      animation: spin 1.5s linear infinite;
    }
  }
}
